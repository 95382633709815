<app-page-loader></app-page-loader>
<!-- <ng-template *ngIf="loadPage === true"> -->
<app-header></app-header>
<app-header-sticky></app-header-sticky>
<app-header-mobile></app-header-mobile>
<div class="container-fuild">
  <nav aria-label="breadcrumb">
    <div class="container">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="'/'">{{ "Home" | translate }}</a>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ "Offers Page" | translate }}
        </li>
      </ol>
    </div>
  </nav>
</div>

<ng-template #dealModalPopup let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Best Deal!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-deal">
    <div class="coupon-container mt-2 mb-2">
      <h2>🎁 Get Your {{ this.dealModalPopupDealString }} Coupon!</h2>
      <p><span class='span-color-change'>Send us your email to receive your free {{ this.dealModalPopupDealString }} coupon code <b>{{ this.dealModalPopupText }}.</b></span></p>
      <form>
          <input type="email" #couponCodeEmail name="couponCodeEmail" placeholder="Enter your email address" required>
          <button type="submit" #couponCodeButton (click)="attachRandomUser(couponCodeEmail)">Send My Coupon Code</button>
      </form>
      <p class="terms">By providing your email, you agree to our <a href="/terms" target="_blank">marketing terms and conditions</a>.</p>
  </div>
  </div>
</ng-template>

<section class="pro-content product-content">
  <div class="container">
    <div class="product-detail-info">
      <div class="row">
        <div class="col-12 col-sm-12">
          <div class="row">
            <div class="col-12 col-md-6 col-xl-5 sticky-wrapper">
              <div class="slider-wrapper">
                <ngx-slick-carousel
                  #slickModal="slick-carousel"
                  [config]="slideProductConfig"
                  (init)="slickInit($event)"
                  class="carousel feedback slider-for"
                >
                  <a
                    ngxSlickItem
                    class="slide slider-for__item fancybox-button"
                    *ngFor="
                      let image_gallerys of productImages;
                      let ind = index
                    "
                  >
                    <lib-ngx-image-zoom
                      (click)="open(ind)"
                      [thumbImage]="image_gallerys.image"
                      [fullImage]="image_gallerys.image"
                    ></lib-ngx-image-zoom>
                  </a>
                </ngx-slick-carousel>

                <ngx-slick-carousel
                  #slickModal="slick-carousel"
                  [config]="slideProductNavConfig"
                  (init)="slickInit($event)"
                  class="carousel thumbs"
                >
                  <div
                    ngxSlickItem
                    class="slide slider-nav__item"
                    *ngFor="let image_gallerys of productImages"
                  >
                    <img src="{{ image_gallerys.image }}" alt="Zoom Image" />
                  </div>
                </ngx-slick-carousel>
              </div>
            </div>
            <!-- </div> -->
            <!-- </div> -->
            <!-- </div> -->
            <div class="col-12 col-md-6 col-xl-7">
              <div class="row">
                <div class="col-12 col-md-12">
                  <span>{{ getProductDetailsData?.category_group }} | {{ getProductDetailsData?.category }}</span><span *ngIf="getProductDetailsData?.sub_category1 != null && getProductDetailsData?.sub_category1 != ''"> | {{ getProductDetailsData?.sub_category1 }}</span><span *ngIf="getProductDetailsData?.sub_category2 != null && getProductDetailsData?.sub_category2 != ''"> | {{ getProductDetailsData?.sub_category2 }}</span>
                  <h1 class="pro-title"><b>
                      {{ getProductDetailsData?.title }}</b>
                  </h1>
                  <div class="box">
                    <div class="field">
                      <span class="tag has-text-link-dark is-medium timezone-text-wrap pt-3 pb-3">All time slots are in Indian Standard Time (IST).</span><br /><br />
                    </div>
                  <div class="pro-rating">
                    <ng-container *ngFor="let item of [].constructor(productRating.rating), let ind = index">
                      <ng-template [ngIf]="(ind + 1)%2 == 0">
                        <i class="fas fa-star"></i>
                      </ng-template>
                      <ng-template [ngIf]="(ind + 1)%2 == 1 && (ind + 1) == productRating.rating">
                        <i class="fas fa-star-half-alt"></i>
                      </ng-template>  
                    </ng-container>
                    <ng-template [ngIf]="productRating.likes != null && productRating.likes > 0">
                      &nbsp;&nbsp;<i class="fas fa-thumbs-up"></i>&nbsp;{{ likes }}
                    </ng-template>
                  </div>
                  <div class="pro-infos">
                    <ng-template [ngIf]="offerList !== null && 
                                        offerList.hasOwnProperty('offer_discount_percentage') && 
                                        offerList.offer_discount_percentage !== null && 
                                        offerList.offer_discount_percentage !== 0 && 
                                        offerList.offer_discount_percentage !== '' && (
                                        (offerList.hasOwnProperty('schedule_hourly_price') && 
                                        offerList['schedule_hourly_price'] > 0) || 
                                        (offerList.hasOwnProperty('schedule_daily_price') &&
                                        offerList['schedule_daily_price'] > 0) ||
                                        (offerList.hasOwnProperty('schedule_weekly_price') &&
                                        offerList['schedule_weekly_price'] > 0) ||
                                        (offerList.hasOwnProperty('schedule_monthly_price') &&
                                        offerList['schedule_monthly_price'] > 0) ||
                                        (offerList.hasOwnProperty('schedule_custom_price') &&
                                        offerList['schedule_custom_price'] > 0)
                                        )">
                      <span class="button is-static has-background-danger-light has-text-danger-dark mb-4"> 
                        <span class="fa-stack">
                          <i class="fa fa-certificate fa-stack-2x"></i>
                          <i class="fa fa-tag fa-stack-1x fa-inverse"></i>
                        </span>&nbsp;{{ offerList.offer_discount_percentage }}%&nbsp;Discount
                      </span><br />
                      <ng-template [ngIf]="offerList !== null && offerList.hasOwnProperty('offer_discount_end_time') && offerList['offer_discount_end_time'] !== '' && offerList['offer_discount_end_time'] !== null ">
                        <div class="button is-static has-background-primary-light has-text-primary-dark mb-4 pt-5 pb-5"> 
                          Lose discount after: 
                        <br />
                          {{ offerList.offer_discount_end_time }}
                        </div>
                      </ng-template>
                    </ng-template>
                    <ng-template [ngIf]="statusdata === 'yes'">
                    <ng-template [ngIf]="offerList !== null && offerList.hasOwnProperty('schedule_hourly_price') && offerList['schedule_hourly_price'] !== '' && offerList['schedule_hourly_price'] !== null ">
                      <ng-template [ngIf]="offerList['schedule_hourly_discounted_price'] !== '' && offerList['schedule_hourly_discounted_price'] !== null && offerList['schedule_hourly_price'] !== 0">
                        <div class="pro-single-info">  
                          <b>Hour Price / Booking Price:</b> <s>{{ offerList.schedule_hourly_price | curency }}</s>&nbsp;<div class="offer-price-custom">{{ offerList.schedule_hourly_discounted_price | curency }}
                              <ng-template [ngIf]="offerList.hourly_multiple_price_flag == true">
                                *
                              </ng-template>
                            </div>
                            </div>
                      </ng-template>
                      <ng-template [ngIf]="offerList['schedule_hourly_discounted_price'] === '' || offerList['schedule_hourly_discounted_price'] === null || offerList['schedule_hourly_price'] === 0">
                      <div class="pro-single-info">  
                      <b>Hour Price / Booking Price:</b> <div class="offer-price-custom">{{ offerList.schedule_hourly_price | curency }}
                          <ng-template [ngIf]="offerList.hourly_multiple_price_flag == true">
                            *
                          </ng-template>
                        </div>
                        </div>
                    </ng-template>
</ng-template>
                    <ng-template [ngIf]="offerList !== null && offerList.hasOwnProperty('schedule_daily_price') && offerList.schedule_daily_price !== '' && offerList.schedule_daily_price !== null ">
                      <ng-template [ngIf]="offerList['schedule_daily_discounted_price'] !== '' && offerList['schedule_daily_discounted_price'] !== null && offerList['schedule_daily_price'] !== 0">
                        <div class="pro-single-info">  
                          <b>Day Price:</b> <s>{{ offerList.schedule_daily_price | curency }}</s>&nbsp;<div class="offer-price-custom">{{ offerList.schedule_daily_discounted_price | curency }}
                              <ng-template [ngIf]="offerList.daily_multiple_price_flag == true">
                                *
                              </ng-template>
                            </div>
                            </div>
                      </ng-template>
                      <ng-template [ngIf]="offerList['schedule_daily_discounted_price'] === '' || offerList['schedule_daily_discounted_price'] === null || offerList['schedule_daily_price'] === 0">
                      <div class="pro-single-info">
                        <b>Day Price:</b> <div class="offer-price-custom">{{ offerList.schedule_daily_price | curency }}
                          <ng-template [ngIf]="offerList.daily_multiple_price_flag == true">
                            *
                          </ng-template>
                        </div>
                          </div>
                    </ng-template>
</ng-template>
                    <ng-template [ngIf]="offerList !== null && offerList.hasOwnProperty('schedule_weekly_price') && offerList.schedule_weekly_price !== '' && offerList.schedule_weekly_price !== null ">
                      <ng-template [ngIf]="offerList['schedule_weekly_discounted_price'] !== '' && offerList['schedule_weekly_discounted_price'] !== null && offerList['schedule_weekly_price'] !== 0">
                        <div class="pro-single-info">  
                          <b>Week Price:</b> <s>{{ offerList.schedule_weekly_price | curency }}</s>&nbsp;<div class="offer-price-custom">{{ offerList.schedule_weekly_discounted_price | curency }}
                              <ng-template [ngIf]="offerList.weekly_multiple_price_flag == true">
                                *
                              </ng-template>
                            </div>
                            </div>
                      </ng-template>
                      <ng-template [ngIf]="offerList['schedule_weekly_discounted_price'] === '' || offerList['schedule_weekly_discounted_price'] === null || offerList['schedule_weekly_price'] === 0">
                      <div class="pro-single-info">
                        <b>Week Price:</b> <div class="offer-price-custom">{{ offerList.schedule_weekly_price | curency }}
                          <ng-template [ngIf]="offerList.weekly_multiple_price_flag == true">
                            *
                          </ng-template>
                        </div>
                          </div>
                    </ng-template>
</ng-template>
                    <ng-template [ngIf]="offerList !== null && offerList.hasOwnProperty('schedule_monthly_price') && offerList.schedule_monthly_price !== '' && offerList.schedule_monthly_price !== null ">
                      <ng-template [ngIf]="offerList['schedule_monthly_discounted_price'] !== '' && offerList['schedule_monthly_discounted_price'] !== null && offerList['schedule_monthly_price'] !== 0">
                        <div class="pro-single-info">  
                          <b>Month Price:</b> <s>{{ offerList.schedule_monthly_price | curency }}</s>&nbsp;<div class="offer-price-custom">{{ offerList.schedule_monthly_discounted_price | curency }}
                              <ng-template [ngIf]="offerList.monthly_multiple_price_flag == true">
                                *
                              </ng-template>
                            </div>
                            </div>
                      </ng-template>
                      <ng-template [ngIf]="offerList['schedule_monthly_discounted_price'] === '' || offerList['schedule_monthly_discounted_price'] === null || offerList['schedule_monthly_price'] === 0">
                      <div class="pro-single-info">
                        <b>Month Price:</b> <div class="offer-price-custom">{{ offerList.schedule_monthly_price | curency }}
                          <ng-template [ngIf]="offerList.monthly_multiple_price_flag == true">
                            *
                          </ng-template>
                        </div>
                          </div>
                    </ng-template>
</ng-template>
                    <ng-template [ngIf]="offerList !== null && offerList.hasOwnProperty('schedule_custom_price') && offerList['schedule_custom_price'] !== '' && offerList['schedule_custom_price'] !== null ">
                      <ng-template [ngIf]="offerList['schedule_custom_discounted_price'] != '' && offerList['schedule_custom_discounted_price'] != null && offerList['schedule_custom_price'] !== 0">
                        <div class="pro-single-info">  
                          <b>Custom Price:</b> <s>{{ offerList.schedule_custom_price | curency }}</s>&nbsp;<div class="offer-price-custom">{{ offerList.schedule_custom_discounted_price | curency }}
                              <ng-template [ngIf]="offerList.custom_multiple_price_flag == true">
                                *
                              </ng-template>
                            </div>
                            </div>
                      </ng-template>
                      <ng-template [ngIf]="offerList['schedule_custom_discounted_price'] === '' || offerList['schedule_custom_discounted_price'] === null || offerList['schedule_custom_price'] === 0">
                      <div class="pro-single-info">  
                      <b>Custom Price:</b> <div class="offer-price-custom">{{ offerList.schedule_custom_price | curency }}
                          <ng-template [ngIf]="offerList.custom_multiple_price_flag == true">
                            *
                          </ng-template>
                        </div>
                        </div>
                    </ng-template>
</ng-template>
                    <br />
                    <div class="container">
                      <div *ngIf="offerList !== null && offerList.hasOwnProperty('offer_discount_percentage') && offerList['offer_discount_percentage'] !== null && offerList['offer_discount_percentage'] > 0" class="row">
                        <div class="col-sm-5 col-md-7 col-lg-5 col-xl-5 col-8">
                          <img src="assets/images/shop/dont_miss.png" class="pb-3" width="100" height="100" />  
                        </div>
                    </div>
                      <div class="row">
                        <div class="col-sm-5 col-md-7 col-lg-5 col-xl-5 col-8">
                          <button type="button" class="btn btn-secondary btn-lg swipe-to-top" (click)="scrollToBookingSection()">
                            Book now
                          </button>  
                        </div>
                        <div *ngIf="validUser === true" class="col-sm-1 col-md-1 col-xl-1 col-lg-1 col-2">
                          <a (click)="onAddLike(this.productselectedId)"><i class="icon-color-custom fa-2x fas fa-thumbs-up"></i></a>
                        </div>
                    </div>
                    </div>
                    <br /><br />
                  </ng-template>
                    <div class="text-wrap w-100" [innerHTML]="productDescription">
                    </div>
                    <div class="pro-single-info">
                      <b>{{ "Offeror Name" | translate }}:</b>
                      <div class="text-wrap">
                        <a class="nav-link" [routerLink]="['/public-profile/user/', hashed_lender_id]">{{ getProductDetailsData?.lender_first_name }} {{ getProductDetailsData?.lender_last_name }}</a>
                    </div>
                    </div>
                    <div *ngIf="getCategoriesAddress">
                      <div class="pro-single-info">
                        <b>{{ "Address line1" | translate }} :</b>{{ getProductDetailsData?.address_line1 }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "Address line2" | translate }} :</b>{{ getProductDetailsData?.address_line2 }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "Landmark" | translate }} :</b>{{ getProductDetailsData?.landmark }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "Area name" | translate }} :</b>{{ getProductDetailsData?.area_name }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "Pincode" | translate }} :</b>{{ getProductDetailsData?.pin_code }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "City" | translate }} :</b>{{ getProductDetailsData?.city }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "District" | translate }} :</b>{{ getProductDetailsData?.district }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "State" | translate }} :</b>{{ getProductDetailsData?.state }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "Country" | translate }} :</b>{{ getProductDetailsData?.country }}
                      </div>
                      <div class="pro-single-info">
                        <b>{{ "Country Abbreviation" | translate }} :</b>{{ getProductDetailsData?.country_abbreviation
                        }}
                      </div>
                    </div>
                    <div class="pro-single-info">
                      <b>{{ "Available" | translate }} :</b> {{statusdata}}
                    </div>
                    </div>
                  </div>
                  <div class="pro-sub-buttons">
                    <!-- AddToAny BEGIN -->
                    <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
                      <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
                      <a class="a2a_button_facebook"></a>
                      <a class="a2a_button_twitter"></a>
                      <a class="a2a_button_email"></a>
                    </div>
                    <script async src="https://static.addtoany.com/menu/page.js"></script>
                    <!-- AddToAny END -->
                  </div>
                </div>
              </div>

              <div class="row no-border mb-5">
                <div class="col-12 col-md-12 no-border">
                  <div class="box has-background-info-light">
                    <span class="has-text-info"><i class="fas fa-shield-halved"></i>&nbsp;&nbsp;Zigwik Guarantee: </span><b>100% Refund</b> if you are not satisfied by the Offer. Just raise a refund request from the email that you would receive after booking. Once you have booked the Offer, 
                    please check your emails from Zigwik for more details about refund request. Not applicable to free Offers.
                </div>
              </div>
              </div>

              <div class="row no-border">
                <div class="col-12 col-md-12 no-border">
                  <div class="box mb-2">
                  <div class="nav nav-pills" role="tablist">
                    <a class="nav-link nav-item" href="#description" id="description-tab" data-toggle="pill"
                      role="tab" aria-controls="description"><span class="has-background-info-light p-2">{{ "Guidelines" | translate }} <i class="fas fa-hand-point-down"></i></span></a>
                      <ng-template [ngIf]="getProductAttributeData != null && getProductAttributeData.length > 0">
                    <a class="nav-link nav-item" href="#additionalInfo" id="additional-info-tab" data-toggle="pill"
                      role="tab" aria-controls="additionalInfo"><span class="has-background-info-light p-2">{{ "Additional information" | translate }} <i class="fas fa-hand-point-down"></i></span></a>
                    </ng-template>
                  </div>
                  <div class="tab-content no-border">
                    <div role="tabpanel" class="tab-pane fade show no-border" id="description" aria-labelledby="description-tab" aria-selected="true">
                          <div class="text-wrap no-border" [innerHTML]="guidelines">
                          </div>
                    </div>
                    <ng-template [ngIf]="getProductAttributeData != null && getProductAttributeData.length > 0">
                    <div role="tabpanel" class="tab-pane fade no-border" id="additionalInfo" aria-labelledby="additional-info-tab" aria-selected="false">
                      <table class="table table-striped table-borderless">
                        <tbody>
                          <tr>
                            <th scope="row">Attribute Key</th>
                            <th scope="row">Attribute Value</th>
                          </tr>
                          <tr *ngFor="let p of getProductAttributeData">
                            <td>{{p.attribute_key}} </td>
                            <td>{{p.attribute_value}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ng-template>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </section>

  <section id="booking-container" class="pro-content add-cart-content" *ngIf="statusdata === 'yes'">
    <div class="container">
  <div class="row">
    <div class="col-12 col-lg-9">
      <div class="profileform">
  <form [formGroup]="frmAddCart">
    <div class="container">
      <div class="field max-vw-100 field is-horizontal field-body form-group">
      <label class="label">Select Schedule for booking </label>
    </div>
    <!-- <div class="field max-vw-100 field is-horizontal field-body form-group text-danger"  *ngIf="validUser === false">
      Please login before booking the offer.
    </div>
    <div class="field max-vw-100 field is-horizontal field-body form-group text-danger"  *ngIf="validUser === true && userAddressCheck === false">
      Please update your profile before continuing. You need to give your address so that we can provide you with appropriate invoice.
    </div> -->
    <button class="button is-small mb-3 is-link is-outlined" type="button" (click)="toggleDisplayDiv()">
      <i class="fas fa-filter"></i>&nbsp;<span *ngIf="isShowDiv == false">Hide&nbsp;</span><span *ngIf="isShowDiv == true">Show&nbsp;</span>Advanced Filter
    </button>
      <div [hidden]="isShowDiv" class="field is-horizontal form-group" id="collapsibleDiv">
        <div class="field-body">
      <div class="field mw-50 max-vw-100">
        <label class="label">Schedule Type</label>
        <p class="control is-expanded has-icons-right">
          <select #scheduleFrequencyOptions formControlName="scheduleFrequency" class="input" name="scheduleFrequency" id="scheduleFrequency">
              <option *ngFor="let scheduleFreq of scheduleFreqMap | keyvalue; let i = index" [value]="scheduleFreq.key" [selected]="i==0">{{ scheduleFreq.value }}</option>
          </select>
          <span class="icon is-small is-right">
            <i class="fas fa-check"></i>
          </span>
        </p>
      </div>
      <div class="field mw-25 max-vw-100">
        <label class="label">Select Days</label>
        <ngb-datepicker #dp (dateSelect)="onDateSelection($event)" [displayMonths]="1" [dayTemplate]="t" outsideDays="hidden">
        </ngb-datepicker>
        
        <ng-template #t let-date let-focused="focused">
          <span class="select-cart-custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
      <div class="field mw-25 max-vw-100">
        <div class="field-body">
        <label class="label">Start Date</label>
      </div>
      <div class="field-body">
        {{ startYear }}/{{ startMonth }}/{{ startDay }}
      </div>
      <div class="field-body">
        <label class="label pt-2">End Date</label>
      </div>
      <div class="field-body">
        {{ endYear }}/{{ endMonth }}/{{ endDay }}
      </div>
      <div class="buttons">
        <div class="pt-4">
        <button id="loadSchedulesButton" class="button is-outline is-primary is-create" (click)="loadSchedules()">
          <span> Filter </span>
        </button>
      </div>
      </div>
      </div>
      </div>
    </div>
    </div>
    <div class="scheduleload">
      <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
        <table class="table table-bordered table-striped mb-0">
          <thead>
            <tr>
              <th scope="col">Select</th>
              <th scope="col">Day&nbsp;&nbsp;&nbsp;</th>
              <th scope="col">Date(s)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
              <th scope="col">Schedules</th>
              <th scope="col">Price</th>
              <th scope="col">Schedule Type</th>
              <th scope="col">Additional details</th>
            </tr>
          </thead>
          <tbody>
<ng-template [ngIf]="loadComplete == false">
              <tr>
                <td colspan="8">
                  <div id="pageloader" class="loader-wrapper is-active d-flex justify-content-center">
                    <div class="loader is-loading"></div>
                  </div>
                </td>
              </tr>
            </ng-template>
            <tr *ngFor="let schedule of productSchedules">
              <td class="text-center"><input type="checkbox" [checked]="checkedBoxMap.get(schedule['cntr'])" [id]="'checkbox_' + schedule['cntr']" (click)="selectCheckBox($event, schedule)"></td>
              <td>{{schedule["week_day"]}}</td>
              <td>{{schedule["schedule_days"]}}</td>
              <td class="text-center">
                <ng-template [ngIf]="schedule['frequency'] == 'weekly' || schedule['frequency'] == 'monthly' || schedule['frequency'] == 'custom'">
                <ng-template #expandedContent let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Select slot for each day</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    
                    <div class="scheduleload">
                    <div class="table-wrapper-scroll-y my-custom-scrollbar-table">
                      <table class="table table-bordered table-striped mb-0">
                        <thead>
                          <tr>
                              <th scope="col">Select</th>
                            <th scope="col">Day</th>
                            <th scope="col">Date</th>
                            <th scope="col">Schedules</th>
                          </tr>
                        </thead>
                        <tbody>
                            <tr class="has-background-info-light">
                              <td><input type="checkbox" [checked]="checkedBoxMap.get(schedule['cntr'])" [id]="'checkboxgran_' + schedule['cntr']" (click)="selectCheckBox($event, schedule, true)">&nbsp;(All)</td>
                              <td>All</td>
                              <td>All</td>
                              <td>
                                <p class="control is-expanded has-icons-right">
                                  <select class="input" (change)="timeChangeAll($event, schedule)" [value]="null" (change)="timeGranularChangeAll($event, schedule)">
                                    <option *ngFor="let refTimeSlot of schedule['allTimeSlots'] | keyvalue" [value]="refTimeSlot.key">{{ refTimeSlot.key }}</option>
                                </select>
                                <span class="icon is-small is-right">
                                  <i class="fas fa-check"></i>
                                </span>
                              </p>
                              </td>
                            </tr>
                          <tr *ngFor="let slot of schedule['slots']">
                              <td><input type="checkbox" [checked]="(checkedBoxGranularMap != null && checkedBoxGranularMap.has(schedule['cntr'] + '_' + slot['date']) && checkedBoxGranularMap.get(schedule['cntr'] + '_' + slot['date']))" [id]="'checkbox_' + schedule['cntr'] + '_' + slot['date']" (click)="selectGranularCheckBox($event, schedule, slot)"></td>
                            <td>{{slot["week_day"]}}</td>
                            <td>{{slot["date"]}}</td>
                            <td>
                            <p class="control is-expanded has-icons-right">
                              <select class="input" name="scheduleTimeSlotSelection" [id]="'scheduleTimeSlotSelection' + schedule['cntr'] + '_' + slot['date']" [value]="selectBoxGranularMap.get('scheduleTimeSlotSelection' + schedule['cntr'] + '_' + slot['date'])" (change)="timeGranularChange($event, schedule, slot)">
                                  <option *ngFor="let scheduleTimeSlotTime of slot['time_slots'] | keyvalue" [value]="scheduleTimeSlotTime.key" [selected]="scheduleTimeSlotTime.key === selectBoxGranularMap.get('scheduleTimeSlotSelection' + schedule['cntr'] + '_' + slot['date'])">{{ scheduleTimeSlotTime.key }}</option>
                              </select>
                              <span class="icon is-small is-right">
                                <i class="fas fa-check"></i>
                              </span>
                            </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    </div>

                  </div>
                </ng-template>
                <a *ngIf="schedule['frequency'] == 'weekly' || schedule['frequency'] == 'monthly' || schedule['frequency'] == 'custom'" (click)="openTableModal(expandedContent, schedule)"><i class="far fa-plus-square text-secondary"></i></a>
  
                </ng-template>


              <ng-template [ngIf]="schedule['frequency'] != 'weekly' && schedule['frequency'] != 'monthly' && schedule['frequency'] != 'custom'">
                <p *ngIf="schedule['frequency'] != 'weekly' && schedule['frequency'] != 'monthly' && schedule['frequency'] != 'custom'" class="control is-expanded has-icons-right">
                  <select class="input" name="scheduleSlotSelection" [id]="'scheduleSlotSelection' + schedule['cntr']" (change)="timeChange($event, schedule)">
                      <option *ngFor="let scheduleSlotTime of schedule['schedules'] | keyvalue" [value]="scheduleSlotTime.key">{{ scheduleSlotTime.key }}</option>
                  </select>
                  <span class="icon is-small is-right">
                    <i class="fas fa-check"></i>
                  </span>
                </p>
              </ng-template>
              
              </td>
              <td *ngIf="schedule['discounted_price'] != '' && schedule['discounted_price'] != null">
                  {{schedule["discounted_price"]}}
              </td>
              <td *ngIf="schedule['discounted_price'] == '' || schedule['discounted_price'] == null">
                {{schedule["price"]}}
              </td>
              <td>{{schedule["frequency"]}}</td>
              <td>
                <ng-template #guidelinesContent let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Additional Details</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <b>{{ "Guidelines:" | translate }}</b>
                    <div class="text-wrap" [innerHTML]="schedule['guidelines']">
                    </div>
                    <br/><br/>
                    <b>Days for Approval: </b> {{schedule['buffer_days']}}<br />
                    <b>Full refund before:</b> {{schedule['full_refund_date']}}<br />
                    <b>Half refund before:</b> {{schedule['half_refund_date']}}
                  </div>
                </ng-template>
                <a (click)="openModal(guidelinesContent)"><i class="fas fa-info-circle text-secondary"></i></a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="field max-vw-100 field is-horizontal field-body form-group pt-6">
      <label class="label">Total applicable price: {{ totalPrice }}</label>
    </div>
    <button type="button" class="btn btn-secondary btn-lg swipe-to-top" (click)="addSoftLock()">
      Add to Cart
    </button>
  </form>
  </div></div></div></div>
</section>
<!-- <section class="pro-content add-cart-content" *ngIf="statusdata === 'yes'"> -->
  <div class="container">
<div class="row">
  <div class="col-12 col-lg-9">
  <div class="notification is-link is-light" role="alert">
    If you also want to offer any course, consultation or service on Zigwik, you can do that by clicking on the below button.<br /><br /> 
    <button class="button is-link is-outlined">
      <a class="nounderline" [routerLink]="'/new-offering'"><span><i class="fa fa-gift" aria-hidden="true"></i>
         Create Offer</span></a>
    </button> 
</div>
  </div>
  </div></div>
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-9">
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3086321925552621"
        crossorigin="anonymous"></script>
   <!-- display ad first -->
   <ins class="adsbygoogle"
        style="display:block"
        data-ad-client="ca-pub-3086321925552621"
        data-ad-slot="2617324744"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
   <script>
        (adsbygoogle = window.adsbygoogle || []).push({});
   </script>
      </div></div></div>
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-9">
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3086321925552621"
            crossorigin="anonymous"></script>
       <!-- display ad first -->
       <ins class="adsbygoogle"
            style="display:block"
            data-ad-client="ca-pub-3086321925552621"
            data-ad-slot="2617324744"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
       <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
       </script>
          </div></div></div>
          <div class="container">
            <div class="row">
              <div class="col-12 col-lg-9">
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3086321925552621"
                crossorigin="anonymous"></script>
           <!-- display ad first -->
           <ins class="adsbygoogle"
                style="display:block"
                data-ad-client="ca-pub-3086321925552621"
                data-ad-slot="2617324744"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
           <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
           </script>
              </div></div></div>
<!-- </section> -->
<app-footer-mobile></app-footer-mobile>
<app-footer></app-footer>
<app-accept-cookie></app-accept-cookie>
<app-back-to-top></app-back-to-top>
<!-- <app-switcher></app-switcher> -->
<!-- </ng-template> -->